<template>
  <div>
    <DataTable
      :loading="loading"
      :headers="headers"
      :can-search="true"
      :infinite-scroll="true"
      v-bind="communications"
      class="tw--m-5 tw-shadow-none"
      @filter="loadCommunications"
      @infiniteLoad="infiniteLoad"
      @changedOrder="loadCommunications"
    >
      <template #toolbar>
        <div class="tw-mb-4 tw-flex tw-justify-end">
          <button
            type="button"
            class="action tw-bg-success tw-mr-0"
            @click="showCommunicationModal()"
          >
            <i class="fa fa-plus" /> Communicatie
          </button>
        </div>
      </template>

      <template #filters="{ filterBy }">
        <select
          title="Type"
          placeholder="Type"
          class="tw-border-b tw-bg-white"
          @input="filterBy(['type_id'], $event.target.value)"
        >
          <option value="" selected>Geen type</option>
          <option disabled>---------</option>
          <option
            v-for="type in communicationTypes"
            :key="type.id"
            :value="type.id"
            :title="type.name_reporting_nl"
          >
            {{ type.name_reporting_nl }}
          </option>
        </select>
      </template>

      <template #item-entity="{ item }">
        <EntityHoverable :value="{ ...item.property, type: 'property' }" />
      </template>

      <template #item-address="{ item }">
        <span v-if="item.property">
          {{ item.property.street }} {{ item.property.number }} {{ item.property.box }},
          {{ item.property.city.zip_code }} {{ item.property.city.name }}
        </span>
      </template>

      <template #item-timestamp="{ item }">
        {{ item.timestamp | day-hour }}
      </template>

      <template #item-created_by="{ item }">
        <span v-if="item.created_by">
          {{ item.created_by.last_name }} {{ item.created_by.first_name }}
        </span>
        <span v-else>IAM</span>
      </template>

      <template #item-internal="{ item }">
        <div v-html="item.internal" />
      </template>

      <template #item-external="{ item }">
        <div v-html="item.external" />
      </template>

      <template #item-actions="{ item }">
        <div class="tw-flex">
          <button
            type="button"
            title="Communicatie bewerken"
            class="action tw-bg-success"
            @click="showCommunicationModal(item)"
          >
            <i class="far fa-pencil"/>
          </button>
          <button
            type="button"
            title="Communicatie bewerken"
            class="action tw-bg-danger"
            @click="deleteCommunication(item.id)"
          >
            <i class="far fa-trash-alt"/>
          </button>
          <button
            v-if="!item.match"
            type="button"
            title="Lead aanmaken"
            class="action tw-bg-primary"
            @click="newLead(item)"
          >
            <i class="far fa-user-plus"/>
          </button>
        </div>
      </template>
    </DataTable>

    <CommunicationModal
      ref="communicationModal"
      :contact-id="contact.id"
      @success="loadCommunications"
    />
  </div>
</template>

<script>
import moment from 'moment'

import DataTable from '@/components/DataTable'
import EntityHoverable from '@/components/iam/EntityHoverable'
import CommunicationModal from '@/components/iam/CommunicationModal'

import {
  errorModal,
  questionModal,
  successModal
} from '@/modalMessages'
import { mapActions, mapState } from 'vuex'

import { createActivity } from '@/services/sales'
import { getCommunicationTypes } from '@/services/apiService'
import { getContactCommunications, deleteContactCommunication } from '@/services/contacts'

export default {
  name: 'ContactCommunication',
  components: {
    DataTable,
    CommunicationModal,
    EntityHoverable
  },
  data () {
    return {
      loading: false,
      // The object communications includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      communications: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      actions: [
        {
          key: 'edit',
          type: 'default',
          icon: 'edit',
          title: 'Communicatie bewerken'
        },
        {
          key: 'remove',
          type: 'danger',
          icon: 'trash',
          title: 'Communicatie verwijderen'
        }
      ],
      communicationTypes: []
    }
  },
  computed: {
    ...mapState('contacts', ['contact']),

    headers () {
      return [
        { text: 'Pand/project', value: 'entity' },
        { text: 'Type', value: 'type.name_reporting_nl', orderable: true, orderByKey: 'type__name_reporting_nl' },
        { text: 'Datum', value: 'timestamp', orderable: true },
        { text: 'Gebruiker', value: 'created_by' },
        { text: 'Adres', value: 'address' },
        { text: 'Intern', value: 'internal' },
        { text: 'Extern', value: 'external' },
        { text: 'Actie', value: 'actions' }
      ]
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('sales', [
      'createLead',
      'checkExistingLead'
    ]),

    showCommunicationModal (values) {
      this.$refs.communicationModal.show(values)
    },

    async init () {
      try {
        const response = Promise.all([this.loadCommunicationTypes(), this.loadCommunications()])
        return response
      } catch (error) {
        errorModal('Fout bij het laden van communicatiegegevens, probeer het opnieuw.')
      }
    },

    async getCommunications (payload) {
      const response = await getContactCommunications(payload)
      return response.data
    },
    async loadCommunications (payload) {
      try {
        this.loading = true
        const communications = await this.getCommunications({ contactId: this.contact.id, ...payload })
        this.communications = communications
        this.loading = false
        return communications
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van communicatie, probeer het opnieuw.')
      }
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.communications.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const communications = await this.getCommunications({ url: next })
        const results = [...this.communications.results, ...communications.results]
        this.communications = { ...communications, results }
        $infinite.loaded()
        return communications
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },

    async loadCommunicationTypes () {
      try {
        const response = await getCommunicationTypes()
        this.communicationTypes = response.data.results
        return response
      } catch (error) {
        errorModal('Fout bij het laden van communicatietypen, probeer het opnieuw.')
      }
    },

    async deleteCommunication (communicationId) {
      try {
        const result = await questionModal('Deze actie kan niet ongedaan gemaakt worden')
        if (!result.value) return

        const response = await deleteContactCommunication(this.contact.id, communicationId)
        await this.loadCommunications()
        successModal('Communicatie is verwijderd.')
        return response
      } catch {
        errorModal('Kan communicatie niet verwijderen, gelieve opnieuw te proberen')
      }
    },

    async newLead (communication) {
      try {
        const contact_id = communication?.relation?.id
        const property_id = communication?.property?.id

        const result = await questionModal('Wil je een lead aanmaken voor deze communicatie?')
        if (!result.value) return

        const existingLead = await this.checkExistingLead({ contact_id, property_id })
        if (existingLead) return this.$router.push({ name: 'LeadDetails', params: { id: existingLead.id } })

        const lead = await this.createLead({ candidates: [contact_id], property: property_id })

        const createActivityPayload = {
          type: communication?.type?.id,
          name: communication?.type?.name_reporting_nl,
          description: communication?.internal,
          communication_id: communication?.id,
          start_date: moment().format(),
          end_date: moment().format()
        }
        await createActivity(lead.id, createActivityPayload)
        this.$router.push({ name: 'LeadDetails', params: { id: lead.id } })
        return lead
      } catch (error) {
        const errorMessages = error.responseJSON
        if (errorMessages && errorMessages.length >= 1) {
          const errorMessage = errorMessages[0]
          errorModal(`Er ging iets mis bij het aanmaken van de lead: ${errorMessage}`, true)
        }
        console.error(error)
      }
    }
  }
}
</script>
