var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',_vm._b({staticClass:"tw--m-5 tw-shadow-none",attrs:{"loading":_vm.loading,"headers":_vm.headers,"can-search":true,"infinite-scroll":true},on:{"filter":_vm.loadCommunications,"infiniteLoad":_vm.infiniteLoad,"changedOrder":_vm.loadCommunications},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"tw-mb-4 tw-flex tw-justify-end"},[_c('button',{staticClass:"action tw-bg-success tw-mr-0",attrs:{"type":"button"},on:{"click":function($event){return _vm.showCommunicationModal()}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Communicatie ")])])]},proxy:true},{key:"filters",fn:function(ref){
var filterBy = ref.filterBy;
return [_c('select',{staticClass:"tw-border-b tw-bg-white",attrs:{"title":"Type","placeholder":"Type"},on:{"input":function($event){return filterBy(['type_id'], $event.target.value)}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Geen type")]),_c('option',{attrs:{"disabled":""}},[_vm._v("---------")]),_vm._l((_vm.communicationTypes),function(type){return _c('option',{key:type.id,attrs:{"title":type.name_reporting_nl},domProps:{"value":type.id}},[_vm._v(" "+_vm._s(type.name_reporting_nl)+" ")])})],2)]}},{key:"item-entity",fn:function(ref){
var item = ref.item;
return [_c('EntityHoverable',{attrs:{"value":Object.assign({}, item.property, {type: 'property'})}})]}},{key:"item-address",fn:function(ref){
var item = ref.item;
return [(item.property)?_c('span',[_vm._v(" "+_vm._s(item.property.street)+" "+_vm._s(item.property.number)+" "+_vm._s(item.property.box)+", "+_vm._s(item.property.city.zip_code)+" "+_vm._s(item.property.city.name)+" ")]):_vm._e()]}},{key:"item-timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("day-hour")(item.timestamp))+" ")]}},{key:"item-created_by",fn:function(ref){
var item = ref.item;
return [(item.created_by)?_c('span',[_vm._v(" "+_vm._s(item.created_by.last_name)+" "+_vm._s(item.created_by.first_name)+" ")]):_c('span',[_vm._v("IAM")])]}},{key:"item-internal",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.internal)}})]}},{key:"item-external",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.external)}})]}},{key:"item-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex"},[_c('button',{staticClass:"action tw-bg-success",attrs:{"type":"button","title":"Communicatie bewerken"},on:{"click":function($event){return _vm.showCommunicationModal(item)}}},[_c('i',{staticClass:"far fa-pencil"})]),_c('button',{staticClass:"action tw-bg-danger",attrs:{"type":"button","title":"Communicatie bewerken"},on:{"click":function($event){return _vm.deleteCommunication(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})]),(!item.match)?_c('button',{staticClass:"action tw-bg-primary",attrs:{"type":"button","title":"Lead aanmaken"},on:{"click":function($event){return _vm.newLead(item)}}},[_c('i',{staticClass:"far fa-user-plus"})]):_vm._e()])]}}])},'DataTable',_vm.communications,false)),_c('CommunicationModal',{ref:"communicationModal",attrs:{"contact-id":_vm.contact.id},on:{"success":_vm.loadCommunications}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }